@keyframes textFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    opacity: 0.99;
    text-shadow: 0 0 2px #fff, 0 0 3px #ff00ff, 0 0 5px #ff00ff, 0 0 8px #ff00ff,
      0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff, 0 0 40px #ff00ff;
  }
  20%,
  24%,
  55% {
    opacity: 0.4;
    text-shadow: none;
  }
}

@keyframes textBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pinkGlow {
  from {
    box-shadow: 0 0 10px #ff1493, 0 0 20px #ff1493, 0 0 30px #ff1493,
      0 0 40px #ff1493;
  }
  to {
    box-shadow: 0 0 20px #ff69b4, 0 0 30px #ff69b4, 0 0 40px #ff69b4,
      0 0 50px #ff69b4;
  }
}

@keyframes orangeGlow {
  from {
    box-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500, 0 0 30px #ff4500,
      0 0 40px #ff4500;
  }
  to {
    box-shadow: 0 0 20px #ffa500, 0 0 30px #ffa500, 0 0 40px #ffa500,
      0 0 50px #ffa500;
  }
}

@keyframes purpleGlow {
  from {
    box-shadow: 0 0 10px #800080, 0 0 20px #800080, 0 0 30px #800080,
      0 0 40px #800080;
  }
  to {
    box-shadow: 0 0 20px #ee82ee, 0 0 30px #ee82ee, 0 0 40px #ee82ee,
      0 0 50px #ee82ee;
  }
}

@keyframes elegantPulse {
  0% {
    background-color: #2c3e50; // Dark blue-grey
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: #34495e; // Darker blue-grey
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: #2c3e50; // Dark blue-grey
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
}

@keyframes colorFade {
  0% {
    background-color: #34495e; // Dark blue-grey
  }
  33% {
    background-color: #2c3e50; // Very dark greyish blue
  }
  66% {
    background-color: #8e44ad; // Strong purple
  }
  100% {
    background-color: #34495e; // Dark blue-grey
  }
}

@keyframes gradientShift {
  0% {
    background: linear-gradient(45deg, #2c3e50, #34495e); // Dark gradients
  }
  50% {
    background: linear-gradient(
      45deg,
      #8e44ad,
      #2c3e50
    ); // Dark purple to dark blue-grey
  }
  100% {
    background: linear-gradient(45deg, #2c3e50, #34495e); // Dark gradients
  }
}

@keyframes greenGlow {
  from {
    box-shadow: 0 0 10px #32cd32, 0 0 20px #32cd32, 0 0 30px #32cd32,
      0 0 40px #32cd32;
  }
  to {
    box-shadow: 0 0 20px #7cfc00, 0 0 30px #7cfc00, 0 0 40px #7cfc00,
      0 0 50px #7cfc00;
  }
}

@keyframes blueGlow {
  from {
    box-shadow: 0 0 10px #1e90ff, 0 0 20px #1e90ff, 0 0 30px #1e90ff,
      0 0 40px #1e90ff;
  }
  to {
    box-shadow: 0 0 20px #00bfff, 0 0 30px #00bfff, 0 0 40px #00bfff,
      0 0 50px #00bfff;
  }
}

@keyframes colorChange {
  0% {
    box-shadow: 0 0 10px #fff;
  }
  33% {
    box-shadow: 0 0 10px #00ff00;
  }
  66% {
    box-shadow: 0 0 20px #0000ff;
  }
  100% {
    box-shadow: 0 0 10px #ff0000;
  }
}

@keyframes elegantSlideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(-10%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes scaleUpDown {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
@keyframes elegantBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes blink {
  0%,
  49% {
    opacity: 1;
    box-shadow: 0 0 10px #fff;
  }
  50%,
  100% {
    opacity: 0.5;
    box-shadow: 0 0 20px #fff;
  }
}

@keyframes neonGlow {
  from {
    box-shadow: 0 0 10px #ff4757, 0 0 20px #ff4757, 0 0 30px #ff4757,
      0 0 40px #ff4757;
  }
  to {
    box-shadow: 0 0 20px #ff6b81, 0 0 30px #ff6b81, 0 0 40px #ff6b81,
      0 0 50px #ff6b81;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
