.App {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}
