.photowall {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0); // Semi-transparent overlay
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    z-index: -1;
  }

  .highlight-msg {
    position: relative;
    display: inline-block;
    background-color: rgba(0, 0, 0, 1);
    color: #ffffff;
    font-weight: bold;
    padding: 20px 30px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
    max-width: 70%;
    margin: 20px auto;
    z-index: 1000;

    h2 {
      font-size: 4vw;
      z-index: 1000;
    }

    @keyframes textFlicker {
      0%,
      19%,
      21%,
      23%,
      25%,
      54%,
      56%,
      100% {
        opacity: 0.99;
        text-shadow: 0 0 2px #fff, 0 0 3px #ff00ff, 0 0 5px #ff00ff,
          0 0 8px #ff00ff, 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff,
          0 0 40px #ff00ff;
      }
      20%,
      24%,
      55% {
        opacity: 0.4;
        text-shadow: none;
      }
    }

    @keyframes textBlink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes zoomIn {
      0% {
        transform: scale(0.5);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @keyframes pinkGlow {
      from {
        box-shadow: 0 0 10px #ff1493, 0 0 20px #ff1493, 0 0 30px #ff1493,
          0 0 40px #ff1493;
      }
      to {
        box-shadow: 0 0 20px #ff69b4, 0 0 30px #ff69b4, 0 0 40px #ff69b4,
          0 0 50px #ff69b4;
      }
    }

    @keyframes orangeGlow {
      from {
        box-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500, 0 0 30px #ff4500,
          0 0 40px #ff4500;
      }
      to {
        box-shadow: 0 0 20px #ffa500, 0 0 30px #ffa500, 0 0 40px #ffa500,
          0 0 50px #ffa500;
      }
    }

    @keyframes purpleGlow {
      from {
        box-shadow: 0 0 10px #800080, 0 0 20px #800080, 0 0 30px #800080,
          0 0 40px #800080;
      }
      to {
        box-shadow: 0 0 20px #ee82ee, 0 0 30px #ee82ee, 0 0 40px #ee82ee,
          0 0 50px #ee82ee;
      }
    }

    @keyframes greenGlow {
      from {
        box-shadow: 0 0 10px #32cd32, 0 0 20px #32cd32, 0 0 30px #32cd32,
          0 0 40px #32cd32;
      }
      to {
        box-shadow: 0 0 20px #7cfc00, 0 0 30px #7cfc00, 0 0 40px #7cfc00,
          0 0 50px #7cfc00;
      }
    }

    @keyframes blueGlow {
      from {
        box-shadow: 0 0 10px #1e90ff, 0 0 20px #1e90ff, 0 0 30px #1e90ff,
          0 0 40px #1e90ff;
      }
      to {
        box-shadow: 0 0 20px #00bfff, 0 0 30px #00bfff, 0 0 40px #00bfff,
          0 0 50px #00bfff;
      }
    }

    @keyframes colorChange {
      0% {
        box-shadow: 0 0 10px #fff;
      }
      33% {
        box-shadow: 0 0 10px #00ff00;
      }
      66% {
        box-shadow: 0 0 20px #0000ff;
      }
      100% {
        box-shadow: 0 0 10px #ff0000;
      }
    }

    @keyframes blink {
      0%,
      49% {
        opacity: 1;
        box-shadow: 0 0 10px #fff;
      }
      50%,
      100% {
        opacity: 0.5;
        box-shadow: 0 0 20px #fff;
      }
    }

    @keyframes neonGlow {
      from {
        box-shadow: 0 0 10px #ff4757, 0 0 20px #ff4757, 0 0 30px #ff4757,
          0 0 40px #ff4757;
      }
      to {
        box-shadow: 0 0 20px #ff6b81, 0 0 30px #ff6b81, 0 0 40px #ff6b81,
          0 0 50px #ff6b81;
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .img-content {
    object-fit: contain;
    max-height: 95vh;
    max-width: 65vw;
    min-height: 60vh;
    min-width: 27vw;
    border-radius: 19px;
    position: absolute;

    transition: opacity 1s ease-in-out, transform 1s ease-in-out;

    &.fade-in {
      opacity: 1;
      transform: scale(1);
    }

    &.fade-out {
      opacity: 0;
    }
  }
  .photowall-full-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -3;
    transition: opacity 1s ease-in-out, transform 10s ease-in-out; /* Smooth transitions */
    transform: scale(1); /* Initial scale */
    animation: zoomBackground 20s infinite alternate ease-in-out;
  }

  @keyframes zoomBackground {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }

  .qr-code-img {
    position: absolute;
    bottom: 0;
    width: 10%;
    height: auto;
    display: block;
    z-index: 100;
  }

  .logo-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 10vw; // 10% of the viewport width for responsiveness
    height: auto; // Maintain aspect ratio
    display: block;
    z-index: 100;
    min-width: 13vw;
    min-height: 13vh;
    max-width: 13vw;
    max-height: 13vh;
    width: 13vw;
    height: auto;
  }

  @media (min-width: 1600px) {
    .img-content {
      min-width: 40vw;
      min-height: 60vh;
    }
  }

  @media (max-width: 768px) {
    .img-content {
      width: 80vw;
    }
  }
}

.branding {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #ffffff90;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border-radius: 4%;

  .branding-text {
    font-size: 2vw;
    padding: 0;
    margin: 0;
  }

  .branding-img {
    margin-bottom: -30%;
    margin-top: -30%;
    width: 100%;
  }
}

.fullscreen-wrap {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff60;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 0 4% 4% 0;
  cursor: pointer;

  .lottie-expand {
    width: 50%;
    margin-right: 10px;
  }

  .fullscreen-text {
    font-size: 1.5vw;
  }
}

.no-images-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.no-img-text {
  color: #fff;
  font-size: 2vw;
}

.loading-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
  background: #290628;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background-size: 200% 200%;
  animation: gradient 4s ease infinite;

  .loading-text {
    font-size: 3vw;
    color: #fff;
  }
}

.float-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
}

.left-container-float,
.right-container-float {
  width: 10vw; // Width of each column on the left and right
  height: 100vh;
  position: relative;
}

.left-container-float {
  margin-left: 1rem;
}
.right-container-float {
  margin-right: 1rem;
}

.floating-image {
  position: absolute;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  opacity: 0;
  animation: floatUp 15s linear infinite;
  will-change: transform, opacity; /* Hint for hardware acceleration */
  @media (min-width: 2560px) {
    width: 8vw !important;
    height: 8vw;
  }

  @media (max-width: 2559px) {
    width: 6vw !important; /* Dynamic for smaller screens */
    height: 6vw;
  }
}

// Animation to make images float from the bottom to the top, with fade-in and fade-out
@keyframes floatUp {
  0% {
    transform: translateY(0); // Start from the assigned top position
    opacity: 0;
  }
  10% {
    opacity: 0.8; // Fade in as the image enters the viewport
  }
  80% {
    opacity: 0.8; // Remain visible until near the end
  }
  100% {
    transform: translateY(-200vh); // Move out of the viewport at the top
    opacity: 0; // Fade out as the image exits the viewport
  }
}

.photo-wall-masonry-grid {
  display: flex;
  width: 100%;
}

.photo-wall-masonry-column {
  background-clip: padding-box;
}
.scroll-hidden {
  /* For Chrome, Edge, and Safari */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.scroll-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
}
